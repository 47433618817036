<template>
  <!-- <v-switch v-model="val" outlined hide-details="auto" class="mt-0" v-bind="{...input, ...$attrs}" ></v-switch> -->
  <div>
    <v-card outlined :class="{ 'error--card': errors }">
      <slot name="label" >
        <v-card-subtitle :class="{ 'error--text': errors }">{{
          $attrs["label"]
        }}</v-card-subtitle>
      </slot>
      <DragAndDrop :accept="accept || '*'" :value="[]" @input="joinArrays" v-bind="{ ...input, ...$attrs }"
        :errors="errors" :multiple="multiple">
        <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="slotData">
          <slot :name="slot" v-bind="slotData" />
        </template>
      </DragAndDrop>
      <div class="d-flex justify-center"></div>

      <v-data-table hide-default-footer :items-per-page="-1" :headers="[
        { text: $t('common.tipo'), value: 'TIPO' },
        { text: $t('common.nombre'), value: 'NOMBRE' },
        { text: '', value: 'acciones' }
      ]" :items="val">
        <template v-slot:item.TIPO="{ item: file }">
          <v-select v-model="file.TIPO" clearable outlined dense hide-details
            :items="['NIF/CIF', 'FACTURA', 'RECIBO AUTÓNOMO', 'JUSTO TÍTULO', 'DNI FIRMANTE', 'CONTRATO', 'TOMA DE DATOS', 'LLAMADA VENTA', 'LLAMADA VERIFICACIÓN', 'CONSENTIMIENTO', 'CERTIFICADO DE PODER', 'LLAMADA', 'OTROS' ]"
            :disabled="file.LOCK"></v-select>
        </template>

        <template v-slot:item.NOMBRE="{ item: file }">
          <v-text-field v-model="file.NOMBRE" @change="file.name = [file.NOMBRE, file.EXTENSION].join('')" dense
            hide-details outlined :suffix="file.EXTENSION" :disabled="file.LOCK"></v-text-field>
        </template>

        <template v-slot:item.acciones="{ item: file }">
          <div class="d-flex justify-end">
            <v-btn @click.stop="downloadFile(file, true)" color="primary" small outlined :elevation="0">
              <v-icon left>mdi-eye</v-icon>
              Ver
            </v-btn>
            <v-btn @click.stop="removeFile(file)" color="error darken-1" class="ml-1" small :elevation="0" outlined>
              <v-icon left>mdi-delete</v-icon>
              Borrar
            </v-btn>
          </div>
        </template>

      </v-data-table>

    </v-card>
    <v-input ref="input" :value="val" :rules="rules"></v-input>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { downloadFile as _downloadFile } from "@/utils/index.js";
import types from "@/utils/mimeExtensions.js";

export default {
  props: ["value", "input", "rules", "accept", "multiple"],
  components: {
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  mounted() {
    this.backdoor++
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(v) {
        // this.$emit("input", v);
        if (!this.value) {
          return this.$emit("input", []);
        }
        this.$emit(
          "input",
          Array.from(Array.isArray(v) ? v : [v]).map((file) => {
            const name = file.name || [file.NOMBRE, file.EXTENSION].join(".");
            const res = {
              ...file,
              name,
              NOMBRE: name.replace(/\.[^/.]+$/, ""),
              EXTENSION: `.${name.split(".")[name.split(".").length - 1]}`,
              // size: humanFileSize(file.size),
            };
            if (this.isFile(file))
              res.file = file
            return res
          })
        );
      },
    },
    errors() {
      this.backdoor
      if (this.$refs.input == null) return true
      return this.$refs.input.hasError
    }

  },
  data() {
    return {
      // errors: false,
      files: [],
      backdoor: 0
    };
  },
  methods: {
    isFile(input) {
      return "File" in window && input instanceof File;
    },
    async downloadFile(file) {

      if (typeof file.name == 'string') return _downloadFile(file.file, true);
      else {
        const { data } = await axios({
          url: `/borradores/documento/${file.ARCHIVO}`,
          responseType: "blob",
        });
        const ext = file.ARCHIVO ? file.ARCHIVO.split('.')[file.ARCHIVO.split('.').length - 1].toLowerCase() : 'pdf'
        const f = new File([data], file.NOMBRE, {
          type: types[ext],
        });
        return _downloadFile(f, true);
      }
    },
    async removeFile({ ARCHIVO, file }) {
      if (
        !(await this.$refs.confirm.open(
          this.$t('confirm.eliminar.archivo.title'),
          this.$t('confirm.eliminar.archivo.text'),
          { color: "error darken-2", confirmColor: "error darken-2" }
        ))
      )
        return;

      if (file instanceof File) {
        if (!Array.isArray(this.val)) {
          this.val = null;
          return;
        }
        this.val.splice(
          this.value.findIndex(
            (v) =>
              v.name == file.name &&
              v.size == file.size &&
              v.lastModified == file.lastModified
          ),
          1
        );
      } else {
        axios({
          url: `/borradores/documento/${ARCHIVO}`,
          method: "DELETE",
        });
        this.val.splice(
          this.value.findIndex(({ ARCHIVO: _a }) => _a == ARCHIVO),
          1
        );
      }
    },
    joinArrays(v) {
      this.val = (this.val || []).concat(v)
    }
  },
  watch: {
    "$refs.input.valid": {
      handler(n) {
        if (n != undefined)
          this.$set(this.$data, "errors", !n);
      },
      immediate: true,
    },
  },
};
</script>

<style>
.error--card {
  border-color: var(--v-error-base) !important;
  border-width: 2px !important;
}
</style>
